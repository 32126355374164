import React, { useLayoutEffect, useState } from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import { Navbar as BsNavbar, Nav, NavDropdown } from "react-bootstrap";

import router from "@utils/routes.json";

const Navbar = () => {
    const { pathname, search } = useLocation();

    const [isTransparent, setIsTransparent] = useState(true);
    const [expandNav, setExpandNav] = useState({
        expanded: false,
        class: "not-expanded",
    });

    const handleNavExpanded = expanded => {
        if (expanded) {
            setExpandNav({
                expanded: expanded,
                class: "expanded",
                bg: "expanded-custom",
            });
        } else {
            setExpandNav({
                expanded: expanded,
                class: "not-expanded",
                bg: "",
            });
        }
    };

    const navIsActive = route => {
        return route === pathname ? "active" : "";
    };

    const renderNav = () => {
        return router.slice(0, 7).map((route, idx) => {
            if (route.subMenu) {
                return (
                    <NavDropdown
                        key={idx}
                        title={route.label}
                        id={`collasible-nav-dropdown ${route.label.replace(
                            " ",
                            ""
                        )}`}
                    >
                        <div className="dropdown-item-container">
                            {route.subMenu.map((subRoute, idx) => (
                                <NavDropdown.Item
                                    key={idx}
                                    as={Link}
                                    to={`${subRoute.path}${search}`}
                                    className={navIsActive(subRoute.path)}
                                    onClick={() => handleNavExpanded(false)}
                                >
                                    {subRoute.label}
                                </NavDropdown.Item>
                            ))}
                        </div>
                    </NavDropdown>
                );
            } else {
                return (
                    <Link
                        key={idx}
                        to={`${route.path}${search}`}
                        className={`
                            nav-link nav-link-custom ${navIsActive(route.path)}
                        `}
                        id={route.label.replace(" ", "")}
                        onClick={() => handleNavExpanded(false)}
                    >
                        {route.label}
                    </Link>
                );
            }
        });
    };

    useLayoutEffect(() => {
        const onScroll = () => {
            if (pathname === "/") {
                if (window.scrollY > window.innerHeight) {
                    setIsTransparent(false);
                } else {
                    setIsTransparent(true);
                }
            } else {
                setIsTransparent(false);
            }
        };

        onScroll();

        window.addEventListener("scroll", onScroll);

        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, [pathname]);

    const removeNavOnRoutes =
        pathname.split("/")[1] === "hubungi-kami" ||
        pathname.split("/")[1] === "contact"
            ? "d-none"
            : "";

    return (
        <BsNavbar
            collapseOnSelect
            expanded={expandNav.expanded}
            fixed="top"
            expand="lg"
            bg={expandNav.bg}
            variant="light"
            className={`justify-content-center text-uppercase ${
                isTransparent ? "bg-transparent" : "shadow bg-white"
            } ${expandNav.class} ${removeNavOnRoutes}`}
            style={{ fontSize: 14, transition: "ease .25s" }}
            id="main-navbar"
            onToggle={handleNavExpanded}
        >
            <BsNavbar.Brand
                as={Link}
                to={`/${search}`}
                className="d-flex w-25 mr-auto"
                onClick={() => handleNavExpanded(false)}
            >
                {isTransparent && !expandNav.expanded ? (
                    <img
                        src={require("../assets/images/logo-putih.png")}
                        className="w-50"
                        alt="logo"
                    />
                ) : (
                    <img
                        src={require("../assets/images/logo-hitam.png")}
                        className="w-50"
                        alt="logo"
                    />
                )}
            </BsNavbar.Brand>
            <BsNavbar.Toggle aria-controls="responsive-navbar-nav" />
            <BsNavbar.Collapse id="responsive-navbar-nav" className="w-100">
                <Nav className="w-75 mx-auto justify-content-around main-menu">
                    {renderNav()}
                </Nav>
                <Nav className="w-25 justify-content-end nav">
                    <Link
                        to={`/hubungi-kami/${search}`}
                        className="nav-link nav-link-custom nav-link-contact-us bg-danger text-white"
                    >
                        HUBUNGI KAMI DISINI!
                    </Link>
                </Nav>
            </BsNavbar.Collapse>
        </BsNavbar>
    );
};

export default Navbar;
