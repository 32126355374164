import React from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import { useLocation } from "@reach/router";

import styles from "@cssmodules/footer.module.scss";

const Footer = () => {
    const { pathname } = useLocation();

    const removeFooterOnRoutes =
        pathname.split("/")[1] === "hubungi-kami" ||
        pathname.split("/")[1] === "contact"
            ? "d-none"
            : "";

    return (
        <Container className={removeFooterOnRoutes}>
            <section className="about mb-5">
                <Row>
                    <Col>
                        <Card
                            className="border-0"
                            style={{
                                backgroundColor: "#eee",
                                borderRadius: "1.5rem",
                            }}
                        >
                            <Card.Body className="p-xs-3 p-md-5">
                                <Card.Title className="text-center">
                                    <img
                                        src={require("../assets/images/logo-hitam.png")}
                                        className={styles.logo}
                                        alt="logo"
                                    />
                                </Card.Title>
                                <Card.Text className={styles.about}>
                                    Berlokasi di Jakarta, kami adalah perusahaan
                                    konveksi Seragam Kerja yang sudah
                                    berpengalaman selama bertahun-tahun
                                    menangani kebutuhan seragam kerja. Kami
                                    memiliki tim costumer service yang mampu
                                    merespon dengan cepat segala pertanyaan
                                    maupun hal-hal yang ingin diketahui oleh
                                    klien. Dengan seleksi ketat yang kami
                                    lakukan, kami telah memiliki penjahit –
                                    penjahit seragam kerja yang memiliki
                                    kualitas jahitan rapi khas tailor. Ditambah
                                    dengan tim quality control yang
                                    berpengalaman, kami bisa menjamin dan
                                    memastikan bahwa barang yang dipesan sesuai
                                    dengan keinginan klien baik, kerapian,
                                    ukuran maupun model seragam jaket. Untuk
                                    menyelesaikan problem waktu pengerjaan
                                    seragam kerja yang molor, kami telah
                                    membentuk tim manajemen produksi yang
                                    tertata sehing- ga mampu mendeliver order
                                    klien tepat waktu. Kami memiliki kapasitas
                                    produksi besar, sehingga mampu melayani
                                    order banyak klien dalam waktu yang
                                    bersamaan. Berkenaan dengan bahan material
                                    seragam kerja, kami memberikan garansi bahan
                                    material maupun warna sesuai dengan yang
                                    disepakati dengan klien di awal. Dan
                                    tentunya, kami bisa memberikan harga yang
                                    terjangkau, tanpa men- gurangi kualitas
                                    bahan dan kualitas jahitan. Silakan hubungi
                                    CS kami, dengan senang hati CS kami akan
                                    melayani serta menjawab segala pertanyaan
                                    Anda.
                                </Card.Text>
                                <Card.Text className={styles.workshop}>
                                    Workshop : Jalan Dewi Sartika No.38 Pancoran
                                    Mas Depok, Kota Depok, Jawa Barat 16431
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </section>
        </Container>
    );
};

export default Footer;
